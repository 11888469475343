export const useAuth = {
    state: {
        user: null
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },

    actions: {
        logout({ commit }) {
            commit('setUser', null);
            localStorage.removeItem("user");
        },

        checkUser({ commit }) {
            const user = localStorage.getItem("user");
            if (user) {
                commit("setUser", JSON.parse(user));
            }
        }
    }
}
