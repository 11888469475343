<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { onMounted, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();

const startDate = ref(new Date());
const endDate = ref(new Date());

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const pickerDate = ref([startDate.value, endDate.value]);
const statisticsData = ref({
  excise_duty: 0,
  excise_duty_paid: 0,
  excise_duty_unpaid: 0,
  total_bets: 0,
  total_stake: 0,
  total_winning_bets: 0,
  total_winnings: 0,
  wht: 0,
  wht_paid: 0,
  wht_unpaid: 0,
});

const fetchStatistics = async () => {
  const baseurl = process.env.VUE_APP_API_TAX_URL;
  const user = store.state.user;

  try {
    const response = await axios.post(
        `${baseurl}/statistics`,
        {
          start_date: formatDate(startDate.value),
          end_date: formatDate(endDate.value),
        },
        {
          headers: {
            "api-key": user.auth,
          },
        }
    );

    if (response.data && response.data.length > 0) {
      Object.assign(statisticsData.value, response.data[0]);
    }
  } catch (error) {
    console.error("Error fetching statistics:", error);
  }
};

onMounted(fetchStatistics);
watch(pickerDate, (newDateRange) => {
  if (newDateRange && newDateRange.length === 2) {
    startDate.value = newDateRange[0];
    endDate.value = newDateRange[1];
    fetchStatistics();
  }
});

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="my-4" style="display: flex; flex-direction: row; justify-content: space-between">
      <div class="date-picker-container" style="margin-left: auto;">
        <VueDatePicker v-model="pickerDate" range multi-calendars :enable-time-picker="false" class="datePicker" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Total Bets"
                :value="statisticsData.total_bets?.toLocaleString() || '0'"
                description="Bets Placed"
                :icon="{
                component: 'ni ni-books',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Total Stake"
                :value="`Ksh. ${statisticsData.total_stake?.toLocaleString() || '0'}`"
                description="Gross Stake on bets"
                :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Excise Duty (Stake)"
                :value="`Ksh. ${statisticsData.excise_duty?.toLocaleString() || '0'}`"
                description="To be paid"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Excise Duty (Paid)"
                :value="`Ksh. ${statisticsData.excise_duty_paid?.toLocaleString() || '0'}`"
                description="Paid successfully"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Excise Duty (Unpaid)"
                :value="`Ksh. ${statisticsData.excise_duty_unpaid?.toLocaleString() || '0'}`"
                description="Not yet paid"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Total Winnings"
                :value="`Ksh. ${statisticsData.total_winnings?.toLocaleString() || '0'}`"
                description="Total Winnings"
                :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="Total Winning Bets"
                :value="statisticsData.total_winning_bets?.toLocaleString() || '0'"
                description="No of won bets"
                :icon="{
                component: 'ni ni-books',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="WHT"
                :value="`Ksh. ${statisticsData.wht?.toLocaleString() || '0'}`"
                description="WHT on Winnings"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="WHT (Paid)"
                :value="`Ksh. ${statisticsData.wht_paid?.toLocaleString() || '0'}`"
                description="Paid successfully"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <MiniStatisticsCard
                title="WHT (Unpaid)"
                :value="`Ksh. ${statisticsData.wht_unpaid?.toLocaleString() || '0'}`"
                description="Not yet paid"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
                }"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
 .datePicker .dp--clear-btn {
   display: none !important;
 }
.datePicker {
  background-color: #8392ab;
}
</style>

